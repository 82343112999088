import Lottie from "lottie-react";

import { memo, useEffect, useState } from "react";
import cloudCreditsLottie from "./assets/lottie/cloudCredits.json";
import completeProductLottie from "./assets/lottie/completeProduct.json";
import flexiiPricingLottie from "./assets/lottie/flexiiPricing.json";
import heroSection from "./assets/lottie/heroSection.json";
import postLaunchLottie from "./assets/lottie/postLaunch.json";
import quickReleaseLottie from "./assets/lottie/quickRelease.json";
import scalableLottie from "./assets/lottie/scalable.json";
import startupExpertiseLottie from "./assets/lottie/startupExpertise.json";
import techAdvisoryLottie from "./assets/lottie/techAdvisory.json";
import ai from "./assets/png/ai.png";
import cuptime from "./assets/png/cuptime.png";
import cuptimePrabakharan from "./assets/png/cuptimePrabakharan.png";
import footer from "./assets/png/footer.png";
import logo from "./assets/png/logo.png";
import mobileApps from "./assets/png/mobile-apps.png";
import noukhaLongLogo from "./assets/png/noukha-long-logo.png";
import saas from "./assets/png/saas.png";
import aiWhite from "./assets/png/tech2/aiWhite.png";
import angularWhite from "./assets/png/tech2/angularWhite.png";
import awsWhite from "./assets/png/tech2/awsWhite.png";
import dockerWhite from "./assets/png/tech2/dockerWhite.png";
import gcpWhite from "./assets/png/tech2/gcpWhite.png";
import gitWhite from "./assets/png/tech2/gitWhite.png";
import jenkinsWhite from "./assets/png/tech2/jenkinsWhite.png";
import kubernetsWhite from "./assets/png/tech2/kubernetsWhite.png";
import mongodbWhite from "./assets/png/tech2/mongodbWhite.png";
import nodeWhite from "./assets/png/tech2/nodeWhite.png";
import pythonWhite from "./assets/png/tech2/pythonWhite.png";
import reactWhite from "./assets/png/tech2/reactWhite.png";
import redisWhite from "./assets/png/tech2/redisWhite.png";
import postgresqlWhite from "./assets/png/tech2/terraformWhite.png";
import website from "./assets/png/website.png";
import linkedin from "./assets/png/linkedin.png";
import greenTick from "./assets/png/greenTick.png";
import connectUs from "./assets/png/connectUs.png";

const HeroSection = () => {
  const services = [
    {
      title: "Mobile Apps",
      description:
        "Custom-built mobile solutions for seamless user experiences.",
      icon: mobileApps,
    },

    {
      title: "AI-Powered Apps",
      description:
        "Harness the power of AI for smarter, data-driven applications.",
      icon: ai,
    },
    {
      title: "Websites and Web Apps",
      description:
        "Modern, responsive websites and web apps tailored to your needs.",
      icon: website,
    },
    {
      title: "SaaS Products",
      description: "Scalable SaaS platforms to drive business efficiency.",
      icon: saas,
    },
  ];

  return (
    <div className="font-custom bg-gray-50  flex flex-col items-center">
      <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row mt-16 lg:mt-28 gap-10">
        {/* Left Section */}
        <div className="flex-1">
          <h1 className="font-custom text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-snug text-gray-800">
            <span className="text-green-600 font-bold inline-flex flex-col h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)*0.4)] sm:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)*0.5)] md:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)*0.6)] lg:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)*0.6)] xl:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)*0.9)] overflow-hidden">
              <ul className="block animate-text-slide-3 text-left leading-tight [&_li]:block">
                <li className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                  MVP
                </li>
                <li className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                  MLP
                </li>
                <li className="text-[1.1507375rem] sm:text-[1.30434375rem] md:text-[1.71925rem] lg:text-[2.1480625rem] xl:text-[2.5816375rem]">
                  MWP
                </li>
                <li
                  className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
                  aria-hidden="true"
                >
                  MVP
                </li>
              </ul>
            </span>
            <span className="text-green-600 font-bold  text-base sm:text-1xl md:text-3xl lg:text-3xl xl:text-4xl">
              s
            </span>{" "}
            Made Faster, Startups Made Smarter – With{" "}
            <span className="text-green-600 font-bold">Noukha</span>!
          </h1>
          <div className="flex items-center space-x-3 mt-4">
            <div className="hidden md:block w-10 h-0.5 bg-green-600"></div>
          </div>
          <p className="text-gray-600 mt-4 text-base sm:text-lg max-w-md">
            Build the perfect MVP to stay ahead of the curve crafted in weeks,
            not months, with the latest technologies and customized pricing
            tailored to your vision and budget.
          </p>
          <button
            data-tally-open="wMr5Ek"
            data-tally-align-left="1"
            data-tally-overlay="1"
            data-tally-auto-close="2000"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            className="mt-8 hover:bg-[#43B968] text-white font-bold py-3 px-6 rounded-lg bg-[#179e42]"
          >
            Start Your MVP <span className="ml-4">➔</span>
          </button>
          <div className="">
            <Lottie
              animationData={heroSection}
              loop={true}
              className="max-w-lg md:max-w-lg"
            />
          </div>
        </div>

        {/* Right Section */}
        <div className="bg-white shadow-md rounded-lg w-full lg:w-2/5 px-8 lg:p-10 h-1/2">
          <h2 className="text-gray-600 text-base lg:text-lg mb-8 mt-8 md:mt-0">
            What We Offer
          </h2>
          <ul className="space-y-6 mb-8">
            {services.map((app, index) => (
              <li key={index} className="space-y-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src={app.icon} alt="Icon" className="h-16 w-16 mr-6" />
                    <div>
                      <h3 className="text-gray-900 font-semibold text-lg lg:text-xl">
                        {app.title}
                      </h3>
                      <p className="text-gray-600 text-base lg:text-lg">
                        {app.description}
                      </p>
                    </div>
                  </div>
                </div>
                {index < services.length - 1 && (
                  <div className="border-t border-gray-300 mt-6"></div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// const CardGrid = () => {
//   const cards = [
//     {
//       title: "React.js / React Native",
//       description: "Building dynamic web and mobile apps with seamless UIs",
//       icon: reactNativeIcon,
//     },
//     {
//       title: "Node.js",
//       description: "Speedy, scalable backends for modern apps",
//       icon: nodejsIcon,
//     },
//     {
//       title: "Python",
//       description: "From web apps to AI, Python's got you covered",
//       icon: pythonIcon,
//     },
//     {
//       title: "Nest.js",
//       description: "Building efficient, scalable server-side applications",
//       icon: nextIcon,
//     },
//     {
//       title: "AWS / Google Cloud",
//       description: "Unleashing the cloud's power for your project",
//       icon: cloudIcon,
//     },
//     {
//       title: "Angular",
//       description: "Dynamic, single-page web applications made easy",
//       icon: angularIcon,
//     },
//     {
//       title: "MongoDB",
//       description: "Flexible, scalable NoSQL databases for modern apps",
//       icon: mongodbIcon,
//     },
//     {
//       title: "PostgreSQL",
//       description: "Powerful, reliable relational databases",
//       icon: postgresqlIcon,
//     },
//     {
//       title: "Redis",
//       description: "Super-fast, in-memory data storage",
//       icon: redis,
//     },
//     {
//       title: "Kubernetes / Docker",
//       description: "Effortless containerization and orchestration",
//       icon: kubernetesIcon,
//     },
//     {
//       title: "DevOps Tools",
//       description: "Streamlining CI/CD with Jenkins, Terraform, and Git",
//       icon: devToolIcon,
//     },
//     {
//       title: "LLMs",
//       description: "Building smarter solutions with advanced AI models",
//       icon: aiIcon,
//     },
//   ];

//   return (
//     <>
//       <div className="text-center mt-10 justify-center mb-8">
//         {/* "Our Services" Label */}
//         <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
//           Our Tech
//         </div>
//         <div className="flex items-center justify-center">
//           <div className={`w-10 h-0.5 bg-green-600`}></div>
//         </div>
//       </div>
//       <div className="flex justify-center items-center">
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-6">
//           {cards.map((card, index) => (
//             <div
//               key={index}
//               className={`border border-n05 bg-[#43B968] p-6 rounded-lg flex flex-col justify-center items-center shadow-md text-center transition-transform transform hover:scale-105 hover:shadow-xl`}
//             >
//               <img
//                 src={card?.icon}
//                 alt="Illustration"
//                 className="w-1/6 md:w-1/6 mb-4"
//               />
//               <h3 className="text-xl font-semibold text-white mb-2">
//                 {card.title}
//               </h3>
//               <p className="text-white text-sm">{card.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

const OurClients = () => {
  return (
    <>
      <section
        id="our-work"
        className="text-center  justify-center bg-[#14A842] pt-8"
      >
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4 text-black">
          Our Work
        </div>
        <div className="flex items-center justify-center">
          <div className={`w-10 h-0.5 bg-black`}></div>
        </div>
      </section>
      <section
        id="#why-choose-us"
        className="flex flex-col lg:flex-row items-center bg-[#14A842] text-black px-12 sm:px-20 py-8"
      >
        {/* Left Section */}
        <div className="flex-1 text-center lg:text-left mb-6 lg:mb-0">
          <div className="flex flex-col sm:flex-row  items-start justify-between  sm:p-6">
            {/* Left Section: Logo */}
            <div className="sm:w-64 mr-6 mb-6 bg-white rounded-full px-2.5 py-3">
              <img
                src={cuptime} // Replace with your logo URL
                className="w-20"
              />
            </div>

            {/* Right Section: Content */}
            <div className="flex-2 text-left text-black">
              <h1 className="text-4xl text-black">Cup Time</h1>
              <h2 className="text-xl  mb-4 text-black">
                Brewing Freshness, Delivering Energy
              </h2>
              <p className=" text-black sm:text-left">
                At Cup Time, we deliver freshly brewed filter coffee and tea
                straight to your workplace. Crafted at our dedicated facility,
                every cup is fresh, flavorful, and perfect for boosting
                productivity. Whether it’s a morning start or a midday break,
                Cup Time ensures the perfect cup, every time.
              </p>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="hidden lg:block w-[0.5px] bg-gray-300 h-40 mx-8"></div>

        {/* Right Section */}
        <div className="flex-1 text-left sm:text-center">
          <p className="italic text-white font-medium">
            "Team Noukha did an exceptional job building my MVP, delivering it
            quickly and providing excellent support throughout. Impressed by
            their expertise and efficiency, I entrusted them with developing the
            full product, including mobile apps (Delivery and Customer) and a
            web app. They understood my vision effortlessly and turned it into
            the perfect startup product. I highly recommend their team for their
            dedication and startup expertise"
          </p>
          <a
            href="https://www.linkedin.com/in/prabaharanpv?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            aria-label="LinkedIn"
            className="hover:text-gray-700"
          >
            <div className="flex items-center justify-center lg:justify-start mt-4">
              <img
                src={cuptimePrabakharan}
                alt="Prakarsh Gagdani"
                className="w-12 bg-white rounded-full mr-4"
              />
              <div>
                <h3 className="font-bold text">Prabaharan Venugopal</h3>
                <p className="text-sm text">Founder, Cup Time</p>
              </div>

              <img
                src={linkedin} // Replace with your logo path
                alt="Nouka Logo"
                className="w-8 ml-4"
              />
            </div>
          </a>
        </div>
      </section>
    </>
  );
};
const PricingCards = () => {
  const cards = [
    {
      title: "Design Implementation",
      price: "$1,200 - $1,800",
      subtitle: "",
      description:
        "Seamlessly transform your designs into functional and responsive applications with integrated business logic.",
      features: [
        "One mobile app built with React Native or Flutter",
        "One web app developed using React or Angular",
        "25-35 screens, including basic business logic and API integration",
        "Comprehensive API integration",
        "Basic performance optimization for smooth functionality",
        "Deployment for both Android and iOS platforms",
        "Responsive design implementation for optimal user experience across devices",
        "Secure user authentication",
        "Payment gateway integration",
      ],
      buttonText: "Bring Designs to Life",
    },

    {
      title: "Cloud Solutions",
      price: "$1,200 - $1,800",
      subtitle: "Between",
      description:
        "Scalable and efficient cloud solutions tailored to your project needs.",
      features: [
        "CI/CD pipeline setup (e.g., Jenkins) for 5 microservices, 1 web app, 1 website, and 1 mobile app",
        "Provision of two environments: development and production",
        "Support for any major cloud platform (AWS, Google Cloud, Azure, etc.)",
        "Kubernetes and Docker configuration (if applicable)",
        "Assistance with obtaining and managing cloud credits",
        "Load balancer setup for enhanced performance and reliability",
        "Cloud security measures (e.g., AWS WAF or equivalent)",
        "Domain configuration with providers like GoDaddy, Cloudflare, etc.",
      ],
      buttonText: "Scale with the Cloud",
    },
    {
      title: "MVP Combo",
      price: "$2,500 - $3,500",
      subtitle: "",
      description:
        "Accelerate your product launch with a cost-effective, feature-rich MVP tailored to your business needs.",
      features: [
        "One mobile app built with React Native",
        "One web app developed using React or Angular",
        "20-25 screens (combined total for mobile and web apps)",
        "Basic cloud services implementation, including CI/CD pipeline setup for smooth deployment",
        "Support for development and production environments",
        "Kubernetes and Docker configuration for scalable and efficient architecture",
        "Secure user authentication (OTP or JWT token-based)",
        "MongoDB database implementation",
        "Basic performance optimization for enhanced user experience",
        "Deployment for both Android and iOS platforms",
        "Domain configuration with providers like GoDaddy or Cloudflare",
        "Load balancer setup for improved app performance and reliability",
        "Payment gateway integration (Razorpay or similar)",
        "Microservices integration for modular and scalable app functionality",
      ],
      buttonText: "Launch Your MVP",
      isPopular: true,
    },
    {
      title: "Backend Implementation",
      price: "$1,800 - $2,500",
      subtitle: "",
      description:
        "Build a robust and secure backend with Node.js, integrated with cloud solutions for scalable and efficient performance.",
      features: [
        "Backend API development using Node.js with Express or NestJS",
        "Comprehensive API design and implementation",
        "Secure user authentication (OAuth, OTP, or JWT token-based)",
        "MongoDB or any relational database integration",
        "CI/CD pipeline setup (Jenkins, AWS CodePipeline, or equivalent)",
        "Kubernetes and Docker configuration for containerized and scalable microservices",
        "Cloud support for deployment on platforms like AWS, Google Cloud, or Azure",
        "Provision of development and production environments",
        "Load balancer setup for enhanced reliability and performance",
        "Cloud security measures (e.g., AWS WAF or equivalent)",
        "Assistance with obtaining and managing cloud credits",
        "Domain and DNS configuration with providers like GoDaddy or Cloudflare",
      ],
      buttonText: "Build Your Backend",
    },
    {
      title: "Mobile and Web App Development",
      price: "$2,500 - $3,500",
      subtitle: "Between",
      description:
        "Build high-quality mobile and web applications tailored to your needs.",
      features: [
        "Two mobile apps using React Native or Flutter",
        "One web app built with React or Angular",
        "10-15 screens (inclusive of web and mobile apps, depending on features)",
        "Microservices and web app pipeline setup (AWS, Jenkins, or Devtron)",
        "iOS and Android apps with Play Store/App Store deployment",
        "Google Analytics integration for user insights",
        "Basic payment gateway integration (Razorpay or similar)",
        "MERN or MEAN stack development",
        "Secure user authentication (OTP or JWT token-based)",
        "MongoDB database implementation",
        "Basic performance optimization",
        "Firebase implementation",
        "Scalable architecture with Kubernetes, Docker, and load balancers for microservices",
      ],
      buttonText: "Start Your App Journey",
    },
    {
      title: "Long-Term Partnership",
      price: "Customized Pricing",
      subtitle: "",
      description:
        "Partner with us for continuous development, scaling, and support of your tech products. Our dedicated team ensures seamless collaboration and ongoing innovation to meet your evolving business needs.",
      features: [
        "Mobile and web app development",
        "Backend development with microservices architecture",
        "Secure authentication and authorization",
        "Third-party API integration",
        "Payment and analytics integration",
        "Robust security measures and scalable cloud solutions (AWS, GCP, Azure, and more)",
        "CI/CD pipeline setup for efficient deployments",
        "Regular sprint planning and feature delivery",
        "Performance optimization and scalability enhancements",
        "Ongoing maintenance and support",
      ],
      buttonText: "Contact Us for Your Solution",
    },
  ];

  return (
    <>
      <section id="pricing" className="text-center mt-10 justify-center">
        {/* "Our Services" Label */}
        <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
          Pricing
        </div>
        <div className="flex items-center justify-center">
          <div className={`w-10 h-0.5 bg-green-600`}></div>
        </div>
      </section>
      <div className="bg-white py-10">
        <div className="container mx-auto px-4 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`flex flex-col justify-between relative bg-white border border-gray-200 rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-xl  ${
                card.isPopular ? "ring-2 ring-green-500" : ""
              }`}
            >
              {card.isPopular && (
                <div className="text-center absolute -top-3 -right-3 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
                  Top Pick
                </div>
              )}
              <h2 className="text-center text-lg font-semibold text-gray-800">
                {card.title}
              </h2>
              <p className="text-center text-4xl font-bold text-green-500">
                {card.price}
              </p>
              <p className="text-center text-gray-600 mt-2">
                {card.description}
              </p>
              <ul className="mt-4 space-y-2 flex-1">
                {card.features.map((feature, i) => (
                  <li key={i} className="flex items-center space-x-2">
                    <img
                      src={greenTick} // Replace with your logo path
                      alt="Nouka Logo"
                      className="w-4"
                    />
                    <span className="text-gray-700 text-sm">{feature}</span>
                  </li>
                ))}
              </ul>
              <button
                data-tally-open="wMr5Ek"
                data-tally-align-left="1"
                data-tally-overlay="1"
                data-tally-auto-close="2000"
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                className={`mt-6 w-full py-2 px-4 rounded-md text-white font-semibold ${
                  card.isPopular
                    ? "bg-green-500 hover:bg-green-600"
                    : "bg-gray-800 hover:bg-gray-900"
                }`}
              >
                {card.buttonText}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const Footer = () => {
  return (
    <footer
      className="bg-darkGreen text-white py-10 relative bg-cover bg-center"
      style={{ backgroundImage: `url(${footer})` }}
    >
      <div className=" text-center p-20 sm:p-6 pb-6 sm:pb-8 relative">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Noukha Logo" className="w-10 sm:w-32 md:w-40" />
        </div>
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold  mb-2 relative z-10 text-[#13A842]">
          Launch Your MVP in 15 Days
        </h2>
        <p className="text-xs sm:text-sm md:text-base  mb-4 relative z-10 text-[#13A842]">
          You Name It We Develop It | Your Trusted Tech Partner
        </p>
        <button
          data-tally-open="wMr5Ek"
          data-tally-align-left="1"
          data-tally-overlay="1"
          data-tally-auto-close="2000"
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          className="bg-black text-white px-4 py-1 sm:px-5 sm:py-2 md:px-6 md:py-3 mt-4 rounded-full hover:bg-gray-800 transition duration-300 ease-in-out relative z-10 text-xs sm:text-sm md:text-base"
        >
          Start Your Project
        </button>

        {/* Icon/Image Divs */}
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "40%",
            left: "20%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={awsWhite}
            alt="React.js / React Native"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "10%",
            left: "30%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={gcpWhite}
            alt="Node.js"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "18%",
            left: "95%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={reactWhite}
            alt="Python"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>

        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "13%",
            left: "83%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={aiWhite} alt="Python" className="w-6 sm:w-8 md:w-10 mr-2" />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "10%",
            left: "70%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={nodeWhite}
            alt="Nest.js"
            className="w-16 sm:w-20 md:w-24 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "60%",
            left: "13%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={pythonWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "18%",
            left: "15%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={gitWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-14 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "5%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={jenkinsWhite}
            alt="AWS / Google Cloud"
            className="w-4 sm:w-6 md:w-10 mr-2 rounded"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "20%",
            left: "5%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={kubernetsWhite}
            alt="AWS / Google Cloud"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "23%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={angularWhite}
            alt="Angular"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "65%",
            left: "95%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={mongodbWhite}
            alt="MongoDB"
            className="w-6 sm:w-12 md:w-14 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "85%",
            left: "88%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={postgresqlWhite}
            alt="MongoDB"
            className="w-8 sm:w-10 md:w-12 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "78%",
            left: "78%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={dockerWhite}
            alt="MongoDB"
            className="w-8 sm:w-10 md:w-12 mr-2"
          />
        </div>
        <div
          className="absolute flex items-center justify-center"
          style={{
            top: "40%",
            left: "85%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={redisWhite}
            alt="MongoDB"
            className="w-6 sm:w-8 md:w-10 mr-2"
          />
        </div>
      </div>

      <div className="border-t border-gray-700 mt-10 pt-4 text-center text-xs md:text-sm">
        <div className="flex justify-between items-center px-4 py-2  text-gray-500 text-sm">
          <div className="flex space-x-4 items-center justify-start w-1/3">
            <a
              href="https://www.linkedin.com/company/noukha/"
              aria-label="LinkedIn"
              className="hover:text-gray-700"
            >
              <img
                src={linkedin} // Replace with your logo path
                alt="Nouka Logo"
                className="w-6"
              />
            </a>
            {/* <p className="text-white">| Crafted In India 🩷</p> */}
          </div>
          <p className="text-white">All Rights Reserved © 2024 | Noukha</p>
          <div className=" w-1/3"></div>
        </div>
      </div>
    </footer>
  );
};

const TawkToWidget = () => {
  useEffect(() => {
    // Dynamically add the Tawk.to script
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/6745ea954304e3196ae9062c/1idkhom8s";
    script.async = true;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once after mount

  return null; // No UI element is rendered
};

const WhoWeAreSection = () => {
  const services = [
    {
      icon: quickReleaseLottie,
      title: "Quick Release Timelines",
      description:
        "In today’s quick commerce era, speed is everything. We deliver MVPs in weeks, not months, helping you launch swiftly and gain a competitive edge.",
    },
    {
      icon: scalableLottie,
      title: "Scalable Architecture with Cloud Solutions",
      description:
        "We build scalable, resilient applications with Docker and Kubernetes, ensuring efficient deployment and resource management.",
    },
    {
      icon: flexiiPricingLottie,
      title: "Flexible Pricing Model",
      description:
        "Our pricing is designed to fit your budget, offering flexible options to ensure you get the best value for your investment.",
    },
    {
      icon: completeProductLottie,
      title: "Complete Product Development",
      description:
        "We specialize in building MVPs and scaling complete products tailored to your needs. Using agile methodology, we ensure flexibility, efficiency, and seamless product evolution.",
    },
    {
      icon: techAdvisoryLottie,
      title: "Tech Advisory",
      description:
        "Leverage our expertise as trusted tech advisors to help you make informed decisions at every stage of your project.",
    },
    {
      icon: startupExpertiseLottie,
      title: "Startup Expertise",
      description:
        "Our team specializes in guiding startups from idea to scale, addressing unique challenges to ensure your success.",
    },
    {
      icon: postLaunchLottie,
      title: "Post-Launch Support Available",
      description:
        "If needed, we’re ready to provide support for 3 to 6 months, even for MVPs, to ensure a smooth transition and early success for your product.",
    },
    {
      icon: cloudCreditsLottie,
      title: "Cloud Credits Assistance",
      description:
        "We help you secure cloud credits from AWS, Google Cloud, and Azure, reducing your initial infrastructure costs and accelerating your startup journey.",
    },
  ];

  return (
    <div className="bg-gray-50 pb-16 pt-4">
      <div className="max-w-6xl mx-auto px-1 sm:px-2 lg-p1">
        <div
          id="why-choose-us"
          className="text-center mt-10 justify-center  mb-8"
        >
          {/* "Our Services" Label */}
          <div className="inline-block text-3xl font-medium px-4 pt-2 rounded-lg mb-4">
            Why Choose Us?
          </div>
          <div className="flex items-center justify-center">
            <div className={`w-10 h-0.5 bg-green-600`}></div>
          </div>
        </div>
        <div className="grid grid-rows-2 gap-y-8 justify-items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {services
              .slice(0, Math.ceil(services.length / 2))
              .map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl px-6 pb-4 hover:scale-105 transition-transform duration-300 max-w-xs flex flex-col items-center text-center"
                >
                  <Lottie
                    animationData={service.icon}
                    loop={true}
                    className="w-48"
                  />
                  <h3 className="text-lg font-semibold mb-4  h-12 text-black">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 text-sm">{service.description}</p>
                </div>
              ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {services
              .slice(Math.ceil(services.length / 2))
              .map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl px-6 pb-4 hover:scale-105 transition-transform duration-300 max-w-xs flex flex-col items-center text-center"
                >
                  <Lottie
                    animationData={service.icon}
                    loop={true}
                    className="w-48"
                  />
                  <h3 className="text-lg font-semibold mb-4  h-12 text-black">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 text-sm">{service.description}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const Header = () => {
  const handleOpenCalendly = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/noukha/30mins?hide_landing_page_details=1&hide_gdpr_banner=1",
    });
  };
  const handleScroll = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <header className="flex justify-between items-center px-4 py-2 bg-white shadow-md">
      {/* Logo */}
      <img
        src={noukhaLongLogo} // Replace with your logo path
        alt="Nouka Logo"
        className="h-8 sm:h-14"
      />

      {/* Navigation */}
      <nav className="hidden md:flex items-center space-x-6">
        <a
          href="#why-choose-us"
          onClick={(e) => handleScroll(e, "why-choose-us")}
          className="text-gray-700 font-semibold hover:text-green-600"
        >
          Why Choose Us?
        </a>

        <a
          href="#our-work"
          onClick={(e) => handleScroll(e, "our-work")}
          className="text-gray-700 font-semibold hover:text-green-600"
        >
          Our Work
        </a>

        <a
          href="#pricing"
          onClick={(e) => handleScroll(e, "pricing")}
          className="text-gray-700 font-semibold hover:text-green-600"
        >
          Pricing
        </a>

        {/* Calendly button for desktop */}
        <button
          onClick={handleOpenCalendly}
          className="font-semibold text-green-600"
        >
          Talk to Our Experts
        </button>
      </nav>

      {/* Mobile View - Calendly button */}
      <div className="flex md:hidden">
        <button
          onClick={handleOpenCalendly}
          className="flex items-center px-2 py-1 border text-green-600 border-green-600 bg-green-100 rounded-md text-black font-semibold hover:bg-green-50 transition-colors text-[calc(3/5*theme(fontSize.base))]"
        >
          <img src={connectUs} alt="icon" className="h-5 w-5 mr-1.5" />
          Talk to Us
        </button>
      </div>
    </header>
  );
};

const HomePage = memo(() => {
  return (
    <>
      <Header />
      <HeroSection />
      <WhoWeAreSection />
      <OurClients />
      <PricingCards />
      <Footer />
      {/* <TawkToWidget /> */}
    </>
  );
});

export default HomePage;
